import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import TermsItem from "../components/TermsItem";
import { Helmet } from "react-helmet";

export const AllTermsQuery = graphql`
  query {
    allCosmicjsTerms(sort: { order: ASC, fields: [slug] }) {
      edges {
        node {
          id
          title
          slug
          content
          metadata {
            description
            term_letter
            is_this_a_page
          }
        }
      }
    }
  }
`;
const Terms = ({ data }) => {
  useEffect(() => {
    window.location.href = `/`;
  }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Digital Marketing Terms Glossary</title>
        <meta
          name="description"
          content="Shore up your digital marketing knowledge with these terms and definitions."
        />
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>
      <div className="Termslist bg-white pb-20 lg:pb-28">
        <div class="bg-gradient-to-r from-cyan-500 to-blue-500">
          <h2 className="py-16 text-center text-3xl leading-9 tracking-tight font-extrabold text-white sm:text-5xl sm:leading-10">
            Marketing Terms
          </h2>
        </div>
        <div className="px-5 md:px-0 relative max-w-lg mx-auto lg:max-w-7xl">
          <div className=" border-gray-200 pt-12  lg:row-gap-24">
            <div className="mx-auto lg:w-3/4">
              {data.allCosmicjsTerms.edges.map((term) => {
                console.log(term);
                return (
                  <div>
                    <TermsItem
                      key={term.node.id}
                      title={term.node.title}
                      description={term.node.metadata.description}
                      slug={"/marketing-terms/" + term.node.slug}
                      abbreviation={term.node.metadata.term_letter}
                      isPage={term.node.metadata.is_this_a_page}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
